import Copper from 'copper-sdk';
import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';

let sdk;

try {
    sdk = Copper.init();
} catch (e) {
    sdk = false;
}

render(!!sdk
    ? <App />
    : <div className={'no_copper'}>This app should be embedded into Copper CRM to run.</div>
    , document.getElementById('root'));

export { sdk };