import { push } from "connected-react-router";
import { call, put, select } from "redux-saga/effects";
import axios from "axios";
import { sdk } from "../index";
import { getLocation } from "ducks/location";
import { actions } from "ducks/auth";
import { actions as doCommon } from 'ducks';
import { saveState } from "utils/localStorage";
import { url_api } from "utils/env";

import { init } from "Services/api";

const loginRequestApiCall = auth =>
  axios.post(
    `${url_api()}/login`,
    {},
    {
      auth,
      validateStatus: () => true
    }
  );

export function* loginRequestSaga({ payload: { username, password } }) {
  const { data, status } = yield call(loginRequestApiCall, {
    username,
    password
  });
  if (status === 200) {
    yield put(actions.doLoginSuccess({ token: data.apiToken }));
    sdk.publishMessage("rp_token", "*", data.apiToken);
  } else {
    yield put(actions.doLoginError(data.error));
  }
}

export function* loginSuccessSaga({ payload }) {
  const location = yield select(getLocation);
  saveState({ app: { auth: { token: payload.token, loggedIn: true } } });
  init(payload.token);
  yield put(doCommon.doRefresh());
  yield put(push(`/${location}`));
}

export function* logoutRequestSaga() {
  localStorage.clear();
  init();
  yield put(actions.doLogoutSuccess());
  sdk.publishMessage("rp_logout", "*", "logout");
}

export function* logoutSuccessSaga() {
  yield put(push(`/login`));
}
