const findEmail = (email, emails) =>
  email ? email.email : emails && !!emails.length ? emails[0].email : null;

const createFullName = (first_name, last_name) =>
  `${!!first_name ? first_name : ""} ${!!last_name ? last_name : ""}`.trim();

const findLinkedIn = (socials) => {
  const linkedIn = socials.find(({ category }) => category === "linkedin");
  return linkedIn ? linkedIn.url : null;
};

export const getSearchDataRaw = ({
  first_name,
  last_name,
  socials,
  emails,
  email,
}) => ({
  fullName: createFullName(first_name, last_name),
  email: findEmail(email, emails),
  linkedinurl: findLinkedIn(socials),
});

export const getSearchData = ({
  first_name,
  last_name,
  socials,
  emails,
  email,
}) => ({
  searchPattern:
    findEmail(email, emails) || createFullName(first_name, last_name),
  linkedinurl: findLinkedIn(socials),
});

export const removeEmptyFields = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([k, v]) => v != null));

export const normalizeNewContact = ({
  first_name,
  last_name,
  middle_name,
  socials,
  emails,
  email,
  phone_numbers = [],
  company_name,
  address = {},
  title,
}) =>
  removeEmptyFields({
    Email: findEmail(email, emails),
    FirstName: `${first_name || ""} ${middle_name || ""}`.trim(),
    LastName: last_name,
    Company: company_name,
    City: address.city,
    State: address.state,
    Country: address.country,
    Phone: phone_numbers[0] && phone_numbers[0].number,
    LinkedInProfileUrl: findLinkedIn(socials),
    Title: title,
    // "SkipDetectingTimezone": true
  });
