import { GET, POST } from './api';

export const getAvailableForNew = () => GET('availableSequencesToAddNewContacts');

export const getAvailable = id => GET(`availableSequencesToAddContact/${id}`);

export const getIsInOther = (contactId, sequenceId) => GET(`isContactExistsInOtherSequence/${contactId}`, { sequenceId });

export const addNew = data => POST('enqueueNewContactsAddingToReply', data);

export const addOld = data => POST('AddContactToSequence', data);