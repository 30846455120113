import { createStore, applyMiddleware, combineReducers } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { createLogger } from "redux-logger";
import reducers from "../ducks";
import createSagaMiddleware from "redux-saga";

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});

export const configureStore = initialState => {
  const middleware = [];
  middleware.push(sagaMiddleware);
  const logger = createLogger({
    level: "info",
    collapsed: true
  });
  if (process.env.NODE_ENV === "development") {
    middleware.push(logger);
  }
  const router = routerMiddleware(history);
  middleware.push(router);

  const enhancer = applyMiddleware(...middleware);

  const store = createStore(rootReducer, initialState, enhancer);
  return store;
};
