import { GET, POST } from "./api";

export const findContact = ({ searchPattern, linkedinurl }) =>
  GET("findContact", {
    searchPattern,
    linkedinurl,
    checkPrivate: true,
  });

export const addContact = contact => POST("contact", contact);
