import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectors, actions as doProspect } from 'ducks/prospect';
import { actions as doSequences, selectors as takeSequences } from 'ducks/sequences';
import { getLocation } from 'ducks/location';
import { getAvailable, getAvailableForNew, addNew, addOld } from 'Services/sequences';
import { types, actions as doCommon } from 'ducks';
import { sdk } from '../index';
import { actions as doToast } from 'ducks/toast';

const prepareSequence = raw => ({ ...raw, steps: [...raw.steps].sort((a, b) => a.number - b.number) });

const prepareSequences = raw => raw.reduce((res, sequence) =>
    ({ ...res, [sequence.id]: prepareSequence(sequence) }), {});

export function* getSequencesSaga() {
    const location = yield select(getLocation);
    if (location !== 'activity_panel') return;
    yield put(doSequences.doUpdateRequest());
    const isNew = yield select(selectors.getIsNew);
    let response;
    if (isNew) {
        response = yield call(getAvailableForNew);
    } else {
        const id = yield select(selectors.getId);
        response = yield call(getAvailable, id);
    }
    if (!!response && response.status === 200) {
        yield put(doSequences.doUpdateSuccess(prepareSequences(response.data.result)));
    } else if (!!response && response.status === 404) {
        yield put(doSequences.doUpdateError('There are no sequences to move this contact to'));
    }
}

export function* addToSequenceWatcher() {
    yield takeLatest(types.ADD_TO_SEQUENCE_REQUEST, addToSequenceSaga);
}

export function* addToSequenceSaga({ payload }) {
    yield put(doProspect.doSetSubmitting(true));
    const isNew = yield select(selectors.getIsNew);
    let response;
    if (isNew) {
        const contact = yield select(selectors.getCopper);
        response = yield call(addNew, { newContacts: [contact], ...payload })
    } else {
        const contactId = yield select(selectors.getId);
        response = yield call(addOld, { contactId, ...payload });
    }
    if (response.status === 200) {
        const name = yield select(takeSequences.getName, payload.sequenceId);
        yield put(doToast.doShow(`Contact has been added to ${name}`));
        yield put(doCommon.doAddToSequenceSuccess(response.data));
        yield put(doProspect.doSetSubmitting(false));

        sdk.logActivity(0, `Added ${isNew ? 'new ' : ''} contact to ${name}.`);
        sdk.publishMessage('reply_contact_added', 'sidebar', { shouldRefresh: true });

        yield put(doCommon.doRefresh());
    } else {
        yield put(doToast.doShow(response.data.error));
        yield put(doProspect.doSetSubmitting(false));
    }
}