import { call, takeLatest } from 'redux-saga/effects';
import { sdk } from '../index';
import { findProspectSaga } from './prospect';
import { types } from 'ducks';


export function* initSDKWatcher() {
    yield takeLatest(types.REFRESH_UI, initSDKSaga);
}

export function* initSDKSaga() {
    let context;
    try {
        context = yield sdk.getContext();
    } catch (e) {
        return;
    }
    yield call(findProspectSaga, context.context);
}