import axios from 'axios';
import { url_api } from 'utils/env';

let instance = null;

export const init = token => {
    instance = axios.create({
        baseURL: url_api(),
        headers: token ? ({
            'X-ApiToken': token
        }) : null
    });
}

const request = (method, url, data) => {
    let methodConfig = { };

    if (data) {
        if (method === 'get') {
            methodConfig = { params: data };
        } else {
            methodConfig = { data };
        }
    }
    let config = {
        method,
        url,
        ...methodConfig
    };

    return new Promise((res) => {
        instance.request(config)
            .then((response) => {
                const {
                    data,
                    status
                } = response;

                res({ data, status });
            })
            .catch((err) => {
                const {
                    data,
                    status
                } = err.response || {};
                if (status) {
                    res({ data, status });
                } else {
                    res({ data: 'Connection to Reply has been lost. Please try again.', status: -1 });
                }
            });
    });
}

export const GET = (url, params) => {
    return request('get', url, params);
}

export const POST = (url, data) => {
    return request('post', url, data);
}

export const PUT = (url, data) => {
    return request('put', url, data);
}

export const PATCH = (url, data) => {
    return request('patch', url, data);
}

export const DELETE = (url) => {
    return request('delete', url);
}