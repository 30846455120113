import { takeLatest } from 'redux-saga/effects';
import { types } from 'ducks/auth';

import { 
    loginRequestSaga, 
    loginSuccessSaga, 
    logoutRequestSaga,
    logoutSuccessSaga
} from './auth';

export { initSDKWatcher } from './onInit';
export { addToSequenceWatcher } from './sequences';

export function* loginRequestWatcher() {
    yield takeLatest(types.LOGIN_REQUEST, loginRequestSaga);
}

export function* loginSuccessWatcher() {
    yield takeLatest(types.LOGIN_SUCCESS, loginSuccessSaga);
}

export function* logoutRequestWatcher() {
    yield takeLatest(types.LOGOUT_REQUEST, logoutRequestSaga);
}

export function* logoutSuccessWatcher() {
    yield takeLatest(types.LOGOUT_SUCCESS, logoutSuccessSaga);
}