import { createSelector } from 'reselect';
// DUCK
const prefix = 'sequences/';
// types
export const types = {
    UPDATE_REQUEST: `${prefix}UPDATE_REQUEST`,
    UPDATE_SUCCESS: `${prefix}UPDATE_SUCCESS`,
    UPDATE_ERROR: `${prefix}UPDATE_ERROR`,
}
// actions
export const actions = {
    doUpdateRequest: () => ({ type: types.UPDATE_REQUEST }),
    doUpdateSuccess: payload => ({ type: types.UPDATE_SUCCESS, payload }),
    doUpdateError: payload => ({ type: types.UPDATE_ERROR, payload }),
}
// reducers
const initialState = {
    list: {},
    isLoading: false,
    error: null,
};

const sequences = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case types.UPDATE_REQUEST:
            return { ...state, isLoading: true };
        case types.UPDATE_SUCCESS:
            return { list: payload, isLoading: false, error: null };
        case types.UPDATE_ERROR:
            return { ...state, error: payload, isLoading: false };
        default:
            return state;
    }
}

export default sequences;
//selectors
const getSequencesState = state => state.app.sequences;
const getIsLoading = state => getSequencesState(state).isLoading;
const getError = state => getSequencesState(state).error;
const getSequences = state => getSequencesState(state).list;
const getAsArray = state => Object.values(getSequences(state));
const getForDD = state => getAsArray(state).map(({ id, name, owner, ownerId }) => ({ id, value: name, owner, ownerId }));
const getSequence = (state, sequenceID) => getSequences(state)[sequenceID] || {};
const getSteps = createSelector(getSequence, sequence => sequence.steps || []);
const getStepsForDD = createSelector(
    getSteps,
    steps => steps.map(({ id, number, stepType: type }) => ({ id, value: `Step ${number}`, type }))
);
const getName = createSelector(getSequence, sequence => sequence.name);

export const selectors = {
    getSequences,
    getIsLoading,
    getError,
    getAsArray,
    getForDD,
    getSteps,
    getStepsForDD,
    getName
}