// DUCK
const prefix = 'auth/';
// types
export const types = {
    LOGIN_REQUEST: `${prefix}LOGIN_REQUEST`,
    LOGIN_ERROR: `${prefix}LOGIN_ERROR`,
    LOGIN_SUCCESS: `${prefix}LOGIN_SUCCESS`,
    LOGOUT_REQUEST: `${prefix}LOGOUT_REQUEST`,
    LOGOUT_SUCCESS: `${prefix}LOGOUT_SUCCESS`,
}
// actions
export const actions = {
    doLoginRequest: payload => ({ type: types.LOGIN_REQUEST, payload }),
    doLoginError: payload => ({ type: types.LOGIN_ERROR, payload }),
    doLoginSuccess: payload => ({ type: types.LOGIN_SUCCESS, payload }),
    doLogoutRequest: payload => ({ type: types.LOGOUT_REQUEST, payload }),
    doLogoutSuccess: payload => ({ type: types.LOGOUT_SUCCESS, payload }),
}
// reducers
const initialState = {
    loggedIn: false,
    token: '',
    error: '',
    submitting: false,
}

const auth = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case types.LOGIN_REQUEST:
            return { ...state, submitting: true }
        case types.LOGIN_ERROR:
            return { ...state, error: payload, submitting: false };
        case types.LOGIN_SUCCESS:
            return { ...payload, loggedIn: true, error: '', submitting: false };
        case types.LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
}

export default auth;
//selectors
const getAuth = state => state.app.auth;
const getToken = state => getAuth(state).token;
const getLoggedIn = state => getAuth(state).loggedIn;
const getError = state => getAuth(state).error;
const getSubmitting = state => getAuth(state).submitting;

export const selectors = {
    getAuth,
    getToken,
    getLoggedIn,
    getError,
    getSubmitting,
}