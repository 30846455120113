import { createSelector } from 'reselect';
// DUCK
const prefix = 'toasts/';
// types
export const types = {
    SHOW: `${prefix}SHOW`,
    HIDE: `${prefix}HIDE`,
}
// actions
export const actions = {
    doShow: payload => ({ type: types.SHOW, payload }),
    doHide: payload => ({ type: types.HIDE, payload }),
}
// reducers
const initialState = {
    isShown: false,
    text: '',
};

const toast = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case types.SHOW:
            return { text: payload, isShown: true };
        case types.HIDE:
            return { ...state, isShown: false };
        default:
            return state;
    }
}

export default toast;
//selectors
const getToast = state => state.app.toast;
const getText = state => getToast(state).text;
const getIsShown = state => getToast(state).isShown;

export const selectors = {
    getToast,
    getText,
    getIsShown,
}