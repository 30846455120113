import { call, put, select, fork, take } from 'redux-saga/effects';
import { selectors, types } from 'ducks/auth';
import { findContact } from 'Services/prospect';
import { actions } from 'ducks/prospect';
import { getSearchData, normalizeNewContact } from 'utils/mapProspect';
import { getSequencesSaga } from './sequences';
import { isEmpty } from 'utils/value';

const isValid = (copper, isNew) =>
    !isNew || (!isEmpty(copper.Email) && !isEmpty(copper.FirstName))

export function* findProspectSaga(context) {
    const loggedIn = yield select(selectors.getLoggedIn);
    if (loggedIn) {
        yield fork(apiCall, context);
    } else {
        yield take(types.LOGIN_SUCCESS);
        yield fork(apiCall, context);
    }
}

export function* apiCall(context) {
    yield put(actions.doUpdateRequest());
    const searchPattern = getSearchData(context);
    const newContact = normalizeNewContact(context);
    const { data, status } = yield call(findContact, searchPattern);
    let isNew = true;
    if (status === 200) {
        isNew = false;
        yield put(actions.doUpdateReply(data.result));
        yield put(actions.doSetIsNew(isNew));
    } else if (status === 404) {
        yield put(actions.doUpdateCopper(newContact));
        yield put(actions.doSetIsNew(isNew));
    } else if (status === 403) {
        yield put(actions.doUpdateError('Contact/Lead cannot be added to sequence'));
        return;
    }
    if (isValid(newContact, isNew)) {
        yield fork(getSequencesSaga);
    }
}