import { combineReducers } from "redux";
import location from './location';
import auth from './auth';
import prospect from './prospect';
import sequences from './sequences';
import toast from './toast';
import modal from './modal';
import { types as AUTH } from "ducks/auth";

const prefix = 'global/';
// types
export const types = {
    ADD_TO_SEQUENCE_REQUEST: `${prefix}ADD_TO_SEQUENCE_REQUEST`,
    ADD_TO_SEQUENCE_SUCCESS: `${prefix}ADD_TO_SEQUENCE_SUCCESS`,
    REFRESH_UI: `${prefix}SDK_INITED`,
}
// actions
export const actions = {
    doAddToSequenceRequest: payload => ({ type: types.ADD_TO_SEQUENCE_REQUEST, payload }),
    doAddToSequenceSuccess: payload => ({ type: types.ADD_TO_SEQUENCE_SUCCESS, payload }),
    doRefresh: () => ({ type: types.REFRESH_UI }),
}
// reducers
const reducer = combineReducers({ auth, prospect, sequences, toast, modal });

const rootReducer = (state, action) => {
    if (action.type === AUTH.LOGOUT_SUCCESS) {
        state = undefined;
    }

    return reducer(state, action);
};

export default { app: rootReducer, location };