import { combineReducers } from 'redux';
// DUCK
const _common = 'prospect/';
const _reply = 'reply/';
const _copper = 'copper/';
// types
export const types = {
    UPDATE_REQUEST: `${_common}UPDATE_REQUEST`,
    UPDATE_REPLY: `${_common}${_reply}UPDATE`,
    UPDATE_COPPER: `${_common}${_copper}UPDATE`,
    UPDATE_ERROR: `${_common}UPDATE_ERROR`,
    SET_IS_NEW: `${_common}SET_IS_NEW`,
    SET_SUBMITTING: `${_common}SET_SUBMITTING`,
}
// actions
export const actions = {
    doUpdateRequest: () => ({ type: types.UPDATE_REQUEST }),
    doUpdateReply: payload => ({ type: types.UPDATE_REPLY, payload }),
    doUpdateCopper: payload => ({ type: types.UPDATE_COPPER, payload }),
    doUpdateError: payload => ({ type: types.UPDATE_ERROR, payload }),
    doSetIsNew: payload => ({ type: types.SET_IS_NEW, payload }),
    doSetSubmitting: payload => ({ type: types.SET_SUBMITTING, payload }),
}
// reducers
const initialState = {
    copper: {},
    reply: {},
    meta: {
        isNew: true,
        isLoading: false,
        submitting: false,
    }
};

const copper = (state = initialState.copper, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case types.UPDATE_COPPER:
            return { ...state, ...payload };
        default:
            return state;
    }
}

const reply = (state = initialState.reply, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case types.UPDATE_REPLY:
            return { ...state, ...payload };
        default:
            return state;
    }
}

const meta = (state = initialState.meta, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case types.UPDATE_REQUEST:
            return { ...state, isLoading: true };
        case types.UPDATE_REPLY:
        case types.UPDATE_COPPER:
            return { ...state, isLoading: false };
        case types.UPDATE_ERROR:
            return { ...state, isLoading: false };
        case types.SET_IS_NEW:
            return { ...state, isNew: payload };
        case types.SET_SUBMITTING:
            return { ...state, submitting: payload };
        default:
            return state;
    }
}

const prospect = combineReducers({ copper, reply, meta });

export default prospect;
//selectors
const getState = state => state.app.prospect;
const getCopper = state => getState(state).copper;
const getReply = state => getState(state).reply;
const getMeta = state => getState(state).meta;
const getIsNew = state => getMeta(state).isNew;
const getIsLoading = state => getMeta(state).isLoading;
const getSubmitting = state => getMeta(state).submitting;
const getNeeded = state => getIsNew(state) ? getCopper(state) : getReply(state);
const getId = state => getReply(state).id;
const getSequences = state => getReply(state).sequenceInfos || [];
const getTaken = state => !!getSequences(state).length;

export const selectors = {
    getState,
    getCopper,
    getReply,
    getNeeded,
    getMeta,
    getIsNew,
    getIsLoading,
    getSubmitting,
    getId,
    getSequences,
    getTaken,
}