import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { selectors as fromAuth } from 'ducks/auth';
import { Switch, Route } from 'react-router-dom';

const Logout = lazy(() => import('Components/Logout'));
const Toast = lazy(() => import('Components/generic/toasts'));

const ROUTES = {
  '/': 'Root',
  '/login': 'Login',
  '/sidebar': 'Sidebar',
  '/activity_panel': 'ActivityPanel',
  '/action_bar': 'ActionBar',
};

const exacts = new Set(['/']);

const routes = Object.entries(ROUTES).map(
  ([path, src]) =>
    <Route
      key={path}
      exact={exacts.has(path)}
      path={path}
      component={lazy(() => import(`Components/${src}`))} />
);

const Routes = ({ loggedIn }) =>
  <Suspense fallback={null}>
    <Switch>
      {routes}
    </Switch>
    {loggedIn && <Logout />}
    <Toast />
  </Suspense>;

export default connect(
  state => ({ loggedIn: fromAuth.getLoggedIn(state) }),
)(Routes);