// DUCK
const prefix = 'modal/';
// types
export const types = {
    OPEN: `${prefix}OPEN`,
    CLOSE: `${prefix}CLOSE`,
}
// actions
export const actions = {
    doOpen: payload => ({ type: types.OPEN, payload }),
    doClose: () => ({ type: types.CLOSE }),
}
// reducers
const initialState = {
    type: null,
    data: {},
};

const modal = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case types.OPEN:
            return payload; // { type, data }
        case types.CLOSE:
            return initialState;
        default:
            return state;
    }
};

export default modal;
// selectors
const getModal = state => state.app.modal;
const getType = state => getModal(state).type;
const getData = state => getModal(state).data;

export const selectors = {
    getModal,
    getType,
    getData,
}