export const url_reply = () =>
    `https://run.${
    process.env.REACT_APP_ENV.trim() === 'prod'
        ? 'reply.io'
        : 'replyapp-stage.net'
    }`;

export const url_api = () =>
    `https://api.${
    process.env.REACT_APP_ENV.trim() === 'prod'
        ? 'reply.io'
        : 'replyapp-stage.net'
    }/api/v1/chrome-extension`;