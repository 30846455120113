import React from 'react';
import { all } from 'redux-saga/effects';
import { configureStore, history, sagaMiddleware } from './store/configureStore';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Routes from './routes';
import { init } from 'Services/api';
import * as sagas from 'Sagas';
import { loadState } from 'utils/localStorage';
// localStorage.clear();
const persistedStore = loadState();

const store = configureStore(persistedStore);

try {
  init(persistedStore.app.auth.token);
} catch (e) {
  init();
}

function* rootSaga() {
  yield all(Object.values(sagas).map(saga => saga()));
}

const App = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  </Provider>;

sagaMiddleware.run(rootSaga);

export default App;